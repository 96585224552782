
<template>
  <div>
    <div class="w1200">
      <div class="top-line"></div>
      <h1 class="center-head">Levaya & Hespedim</h1>
      <div class="big-video">
        <iframe
          title="CSlive"
          width="1200"
          height="700"
          src="https://www.youtube.com/embed/GTi-0swVgVI?feature=oembed"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen=""
          style=""
          class="video"
        ></iframe>
      </div>
    </div>
  </div>
</template>

      <script>
export default {};
</script>